/* eslint-disable comma-dangle */
export default {
  en: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'This event configuration does not allow you to schedule meetings with this participant.',
      attendeeNetworkingDisabled: 'This attendee is not participating in networking spaces',
      networkingDisabled: 'Networking disabled',
      privateChatDisabled: 'Private chat disabled',
      attendeeChatDisabled: 'This attendee doesn\'t allow private chat requests',
      ratingHelp: 'Rate this attendee according to your interest in scheduling a meeting with them. This will help us match you with other attendees you might be interested in.',
      rated: 'Rated',
      contactOrganizer: 'To fully participate in this event, please contact the organizer to find out more.',
      detailsRequired: 'This field is required',
    },
    requestInfo: {
      title: 'Contact info request',
      subtitle: 'Send a message to this attendee to ask them to share their contact information with you.',
      defaultMessage: 'Hello 👋! I would like you to share your contact information with me 😁',
    },
    title: {
      attendees: 'Attendees',
      associatedWith: 'Associated with',
      description: 'Get in contact and schedule private meetings with other attendees.',
      profile: '{{name}}\'s profile',
    },
    filter: {
      filters: 'Filters',
      typeOfAttendee: 'Type of attendee',
      country: 'Country',
      category: 'Category',
      meetingAvailability: 'Meeting availability',
      slot: 'Select a slot',
      filter: 'Filter',
    },
    placeholder: {
      allAttendeeTypes: 'All types',
      noSlotSelected: 'No slot selected',
    },
    data: {
      city: 'City',
      region: 'Region',
      country: 'Country',
      email: 'Email',
      phone: 'Phone',
      companyDescription: 'Company description',
      rating: 'Attendee rating',
      ratingTableTitle: 'Rating',
      attendee: 'Attendee',
      attendeeType: 'Type',
      attendanceMode: 'Modality',
    },
    actions: {
      listView: 'List view',
      gridView: 'Grid view',
      startChat: 'Start chat',
      goToChat: 'Go to chat',
      denyParticipant: 'Not this time',
      allowParticipant: 'Interested in rating',
      resetRating: 'Reset rating',
      requestContactInfo: 'Request contact info',
      sendRequest: 'Send request',
      clearSlot: 'Clear slot',
    },
  },
  es: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'La configuracion de este evento no permite el agendamiento de citas con este participante.',
      attendeeNetworkingDisabled: 'Este asistente no está participando en la rueda de negocios',
      networkingDisabled: 'Rueda de negocios desactivada',
      privateChatDisabled: 'Chat privado desactivado',
      attendeeChatDisabled: 'Este asistente no permite solicitudes de chat privado',
      ratingHelp: 'Califique a otros participantes de acuerdo a su interés en agendar una cita con ellos. Esto nos permitirá emparejarlo con otros participantes en quien esté interesado.',
      rated: 'Calificado',
      contactOrganizer: 'Para participar plenamente en este evento, comuníquese con el organizador para obtener más información.',
      detailsRequired: 'Este campo es obligatorio',
    },
    requestInfo: {
      title: 'Solicitud de información de contacto',
      subtitle: 'Envíe un mensaje a este asistente para pedirles que compartan su información de contacto con usted.',
      defaultMessage: '¡Hola 👋! Me gustaría que compartieras tu información de contacto conmigo 😁',
    },
    title: {
      attendees: 'Asistentes',
      associatedWith: 'Asociado con',
      description: 'Póngase en contacto y programe reuniones privadas con otros asistentes.',
      profile: 'Perfil de {{name}}',
    },
    filter: {
      filters: 'Filtros',
      typeOfAttendee: 'Tipo de asistente',
      country: 'País',
      category: 'Categoría',
      meetingAvailability: 'Disponibilidad de reuniones',
      slot: 'Seleccione un espacio',
      filter: 'Filtrar',
    },
    placeholder: {
      allAttendeeTypes: 'Todos los tipos',
      noSlotSelected: 'No se ha seleccionado ningún espacio'
    },
    data: {
      city: 'Ciudad',
      region: 'Región',
      country: 'País',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      companyDescription: 'Descripción de la compañía',
      rating: 'Calificación del participante',
      ratingTableTitle: 'Calificación',
      attendee: 'Asistente',
      attendeeType: 'Tipo',
      attendanceMode: 'Modalidad',
    },
    actions: {
      listView: 'Vista de lista',
      gridView: 'Vista de cuadrícula',
      clearSlot: 'Borrar selección',
      startChat: 'Iniciar chat',
      goToChat: 'Ir al chat',
      denyParticipant: 'No esta vez',
      allowParticipant: 'Interés en calificar',
      resetRating: 'Eliminar calificación',
      requestContactInfo: 'Solicitar contacto',
      sendRequest: 'Enviar solicitud',
    },
  },
  fr: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'Cette configuration d\'événement ne vous permet pas de programmer des réunions avec ce participant',
      attendeeNetworkingDisabled: 'Ce participant ne participe pas aux espaces de réseautage',
      networkingDisabled: 'Networking désactivé',
      privateChatDisabled: 'Chat privé désactivé',
      attendeeChatDisabled: 'Ce participant n\'autorise pas les demandes de chat privé',
      ratingHelp: 'Évaluez ce participant en fonction de votre intérêt à organiser une réunion avec lui. Cela nous aidera à vous mettre en relation avec d\'autres participants qui pourraient vous intéresser.',
      rated: 'Évalué',
      contactOrganizer: 'Pour participer pleinement à cet événement, veuillez contacter l\'organisateur pour en savoir plus.',
      detailsRequired: 'Ce champ est obligatoire',
    },
    requestInfo: {
      title: "Demande d'informations de contact",
      subtitle: 'Envoyez un message à ce participant pour lui demander de partager ses coordonnées avec vous.',
      defaultMessage: "Bonjour 👋 ! J'aimerais que vous partagiez vos coordonnées avec moi 😁",
    },
    title: {
      attendees: 'Participants',
      associatedWith: 'Associé à',
      description: 'Prenez contact et programmez des réunions privées avec d\'autres participants.',
      profile: 'Profil de {{name}}',
    },
    filter: {
      filters: 'Filtres',
      typeOfAttendee: 'Type de participant',
      country: 'Pays',
      category: 'Catégorie',
      meetingAvailability: 'Disponibilité des réunions',
      slot: 'Sélectionnez un créneau',
      filter: 'Filtre',
    },
    placeholder: {
      allAttendeeTypes: 'Tous les types',
      noSlotSelected: 'Aucun emplacement sélectionné'
    },
    data: {
      city: 'Cité',
      region: 'Région',
      country: 'Pays',
      email: 'Email',
      phone: 'Téléphone',
      companyDescription: 'Description de la compagnie',
      rating: 'Évaluation du participant',
      ratingTableTitle: 'Évaluation',
      attendee: 'Participant',
      attendeeType: 'Type',
      attendanceMode: 'Modalité',
    },
    actions: {
      listView: 'Voir la liste',
      gridView: 'Vue en grille',
      clearSlot: 'Emplacement transparent',
      startChat: 'Démarrer le chat',
      goToChat: 'Aller au chat',
      denyParticipant: 'Une prochaine fois',
      allowParticipant: 'Intéressé par l\'évaluation',
      resetRating: 'Réinitialiser l\'évaluation',
      requestContactInfo: 'Demander des informations de contact',
      sendRequest: 'Envoyer la demande',
    },
  },
  ca: {
    info: {
      currentAttendeeCantRequestMeetingTo:
        "La configuració de l'esdeveniment no permet que agendis reunions amb aquest participant.",
      attendeeNetworkingDisabled:
        'Aquest assistent no participa en els espais de networking',
      networkingDisabled: 'Networking desactivat',
      privateChatDisabled: 'Xat privat desactivat',
      attendeeChatDisabled:
        'Aquest assistent no permet sol·licituds de xat privat',
      ratingHelp:
        "Qualifica aquest assistent d'acord amb el teu interès per agendar una reunió amb ell/a. Això ens ajudarà a connectar-te amb altres assitents que puguin interessar-te.",
      rated: 'Qualificat',
      contactOrganizer:
        "Per participar en aquest esdeveniment, per favor contacta amb l'organització per saber-ne com.",
      detailsRequired: 'Aquest camp és obligatori',
    },
    requestInfo: {
      title: "Petició d'informació de contacte",
      subtitle:
        'Envia un missatge a aquest/a assitent per demanar-li que comparteixi la seva informació de contacte amb tu.',
      defaultMessage:
        "Hola 👋! M'agradaria compartir la teva informació de contacte amb mi 😁",
    },
    title: {
      attendees: 'Assistents',
      associatedWith: 'Associat amb',
      description:
        "Posa't en contacte i agenda reunions privades amb altres assistents",
      profile: "Perfil d'en/na {{name}}",
    },
    filter: {
      filters: 'Filtres',
      typeOfAttendee: "Tipus d'assistent",
      country: 'País',
      category: 'Categoria',
      meetingAvailability: 'Disponibilitat de reunions',
      slot: 'Seleccioneu una ranura',
      filter: 'Filtre',
    },
    placeholder: {
      allAttendeeTypes: 'Tots els tipus',
      noSlotSelected: "No s'ha seleccionat cap ranura"
    },
    data: {
      city: 'Ciutat',
      region: 'Regió',
      country: 'País',
      email: 'Correu-e',
      phone: 'Telèfon',
      companyDescription: 'Descripció de la companyia',
      rating: "Qualificació de l'assistent",
      ratingTableTitle: 'Valoració',
      attendee: 'Assistente',
      attendeeType: 'Tipus',
      attendanceMode: 'Modalitat',
    },
    actions: {
      listView: 'Vista de llista',
      gridView: 'Vista de quadrícula',
      clearSlot: 'Ranura clara',
      startChat: 'Comença xat',
      goToChat: 'Vés a xat',
      denyParticipant: 'No ara mateix',
      allowParticipant: 'Interessat en qualificació',
      resetRating: 'Torna a qualificar',
      requestContactInfo: 'Requereix informació de contacte',
      sendRequest: 'Envia requeriment',
    },
  },
  pt: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'Esta configuração de evento não permite que você agende reuniões com este participante.',
      attendeeNetworkingDisabled: 'Este participante não está participando de espaços de rede',
      networkingDisabled: 'Rede desativada',
      privateChatDisabled: 'Chat privado desativado',
      attendeeChatDisabled: 'Este participante não permite solicitações de chat privado',
      ratingHelp: 'Avalie este participante de acordo com seu interesse em agendar uma reunião com ele. Isso nos ajudará a combiná-lo com outros participantes nos quais você possa se interessar.',
      rated: 'Classificado',
      contactOrganizer: 'Para participar integralmente deste evento, entre em contato com a organizadora para saber mais.',
      detailsRequired: 'Esse campo é obrigatório',
    },
    requestInfo: {
      title: 'Solicitação de informações de contato',
      subtitle: 'Envie uma mensagem para esse participante solicitando que ele compartilhe suas informações de contato com você.',
      defaultMessage: 'Olá 👋! Gostaria que você compartilhasse suas informações de contato comigo 😁',
    },
    title: {
      attendees: 'Participantes',
      associatedWith: 'Associado com',
      description: 'Entre em contato e agende reuniões privadas com outros participantes.',
      profile: 'Perfil de {{name}}',
    },
    filter: {
      filters: 'Filtros',
      typeOfAttendee: 'Tipo de participante',
      country: 'País',
      category: 'Categoria',
      meetingAvailability: 'Disponibilidade de reuniões',
      slot: 'Selecione um slot',
      filter: 'Filtrar',
    },
    placeholder: {
      allAttendeeTypes: 'Todos os tipos',
      noSlotSelected: 'Nenhum slot selecionado'
    },
    data: {
      city: 'Cidade',
      region: 'Região',
      country: 'País',
      email: 'E-mail',
      phone: 'Telefone',
      companyDescription: 'Descrição da empresa',
      rating: 'Classificação do participante',
      ratingTableTitle: 'Classificação',
      attendee: 'Participante',
      attendeeType: 'Tipo',
      attendanceMode: 'Modalidade',
    },
    actions: {
      listView: 'Exibição de lista',
      gridView: 'Vista em grade',
      clearSlot: 'Ranhura transparente',
      startChat: 'Iniciar chat',
      goToChat: 'Ir para o chat',
      denyParticipant: 'Não dessa vez',
      allowParticipant: 'Interessado na classificação',
      resetRating: 'Redefinir classificação',
      requestContactInfo: 'Solicitar informações de contato',
      sendRequest: 'Enviar pedido',
    },
  },
  it: {
    actions: {
      listView: 'Visualizzazione elenco',
      gridView: 'Vista griglia',
      clearSlot: 'Slot trasparente',
      startChat: 'Inizia la chat',
      resetRating: 'Ripristina valutazione',
      goToChat: 'Vai alla chat',
      denyParticipant: 'Non stavolta',
      allowParticipant: 'Interessato al rating',
      requestContactInfo: 'Richiedi informazioni di contatto',
      sendRequest: 'Invia richiesta',
    },
    title: {
      attendees: 'Partecipanti',
      associatedWith: 'Associato a',
      description: 'Mettiti in contatto e pianifica incontri privati con altri partecipanti.',
      profile: 'Profilo di {{name}}'
    },
    data: {
      region: 'Regione',
      rating: 'Valutazione dei partecipanti',
      ratingTableTitle: 'Valutazione',
      email: 'Email',
      phone: 'Telefono',
      country: 'Paese',
      city: 'Città',
      companyDescription: "Descrizione dell'azienda",
      attendee: 'Partecipante',
      attendeeType: 'Tipo',
      attendanceMode: 'Modalità',
    },
    filter: {
      typeOfAttendee: 'Tipo di partecipante',
      country: 'Paese',
      filter: 'Filtra',
      category: 'Categoria',
      meetingAvailability: 'Disponibilità delle riunioni',
      slot: 'Seleziona uno slot',
      filters: 'Filtri'
    },
    info: {
      attendeeNetworkingDisabled: 'Questo partecipante non partecipa a spazi di networking',
      networkingDisabled: 'Rete disattivata',
      privateChatDisabled: 'Chat privata disabilitata',
      ratingHelp: 'Valuta questo partecipante in base al tuo interesse a programmare una riunione con loro. Questo ci aiuterà a metterti in contatto con altri partecipanti che potrebbero interessarti.',
      attendeeChatDisabled: 'Questo partecipante non consente richieste di chat private',
      currentAttendeeCantRequestMeetingTo: "Questa configurazione dell'evento non consente di programmare riunioni con questo partecipante.",
      rated: 'Valutato',
      contactOrganizer: 'Per partecipare a pieno titolo a questo evento, si prega di contattare l\'organizzatore per saperne di più.',
      detailsRequired: 'Questo campo è obbligatorio',
    },
    requestInfo: {
      title: 'Richiesta informazioni di contatto',
      subtitle: 'Invia un messaggio a questo partecipante per chiedere loro di condividere con te le sue informazioni di contatto.',
      defaultMessage: 'Ciao 👋! Vorrei che condividessi le tue informazioni di contatto con me 😁',
    },
    placeholder: {
      allAttendeeTypes: 'Tutti i tipi',
      noSlotSelected: 'Nessuno slot selezionato'
    },
  },
  ja: {
    title: {
      associatedWith: '関連付けられている',
      attendees: '出席者',
      profile: '{{name}} さんのプロフィール',
      description: '他の参加者と連絡を取り、プライベートミーティングをスケジュールします。'
    },
    info: {
      networkingDisabled: 'ネットワークが無効',
      privateChatDisabled: 'プライベートチャットが無効',
      currentAttendeeCantRequestMeetingTo: 'このイベント設定では、この参加者とのミーティングをスケジュールすることはできません。',
      attendeeChatDisabled: 'この出席者はプライベートチャットのリクエストを許可していません',
      attendeeNetworkingDisabled: 'この出席者はネットワーキングスペースには参加していません',
      rated: '定格',
      ratingHelp: 'この出席者を、会議をスケジュールすることに関心があることに応じて評価してください。これは、あなたが興味を持っているかもしれない他の出席者とあなたを一致させるのに役立ちます.',
      contactOrganizer: 'このイベントに完全に参加するには、主催者に連絡して詳細を確認してください。',
      detailsRequired: 'このフィールドは必須です',
    },
    requestInfo: {
      title: '連絡先情報のリクエスト',
      subtitle: 'この出席者にメッセージを送信して、連絡先情報を共有してもらうよう依頼します。',
      defaultMessage: 'こんにちは 👋！あなたの連絡先を教えてほしい 😁',
    },
    filter: {
      filters: 'フィルター',
      category: 'カテゴリー',
      meetingAvailability: '会議の空き状況',
      slot: 'スロットを選んで',
      country: '国',
      typeOfAttendee: '出席者のタイプ',
      filter: 'フィルター'
    },
    data: {
      city: 'シティ',
      companyDescription: '会社概要',
      rating: '出席者評価',
      ratingTableTitle: '評価',
      email: '電子メール',
      phone: '電話',
      region: 'リージョン',
      country: '国',
      attendee: '出席者',
      attendeeType: 'タイプ',
      attendanceMode: 'モダリティ',
    },
    placeholder: {
      allAttendeeTypes: '全種類',
      noSlotSelected: 'スロットが選択されていません'
    },
    actions: {
      listView: 'リストビュー',
      gridView: 'グリッドビュー',
      clearSlot: 'スロットはクリアです',
      goToChat: 'チャットに移動',
      denyParticipant: '今回はじゃない',
      startChat: 'チャットを始める',
      resetRating: '評価をリセット',
      allowParticipant: '評価に興味がある',
      requestContactInfo: '連絡先情報のリクエスト',
      sendRequest: 'リクエストを送る',
    }
  },
  zh: {
    info: {
      rated: '额定',
      networkingDisabled: '网络已禁用',
      attendeeNetworkingDisabled: '此与会者没有参与网络空间',
      attendeeChatDisabled: '此与会者不允许私人聊天请求',
      currentAttendeeCantRequestMeetingTo: '此事件配置不允许您安排与此参与者的会议。',
      ratingHelp: '根据您对安排会议的兴趣对此与会者进行评分。这将有助于我们将您与您可能感兴趣的其他与会者进行匹配。',
      privateChatDisabled: '私人聊天已禁用',
      contactOrganizer: '如需充分参与本次活动，请联系主办方了解更多信息。',
      detailsRequired: '此字段为必填字段',
    },
    requestInfo: {
      title: '联系信息请求',
      subtitle: '向该与会者发送消息，要求他们与您分享联系信息。',
      defaultMessage: '你好 👋！我希望你能和我分享你的联系信息 😁',
    },
    filter: {
      filters: '过滤器',
      category: '類別',
      meetingAvailability: '会议可用性',
      slot: '选择一个插槽',
      country: '国家/地区',
      filter: '筛选',
      typeOfAttendee: '与会者类型'
    },
    title: {
      attendees: '与会者',
      profile: '{{name}} 的个人资料',
      description: '与其他与会者取得联系并安排私人会议。',
      associatedWith: '关联'
    },
    data: {
      region: '地区',
      email: '電郵',
      country: '国家/地区',
      city: '城市',
      phone: '電話',
      companyDescription: '公司描述',
      rating: '与会者评分',
      ratingTableTitle: '评分',
      attendee: '与会者',
      attendeeType: '类型',
      attendanceMode: '方式',
    },
    placeholder: {
      allAttendeeTypes: '所有类型',
      noSlotSelected: '未选择任何插槽'
    },
    actions: {
      listView: '列表视图',
      gridView: '网格视图',
      clearSlot: '清除插槽',
      allowParticipant: '对评分感兴趣',
      goToChat: '去聊天',
      denyParticipant: '这次不行',
      resetRating: '重置评分',
      startChat: '开始聊天',
      requestContactInfo: '索取联系信息',
      sendRequest: '发送请求',
    }
  },
  ar: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'لا يسمح لك تكوين الحدث هذا بجدولة الاجتماعات مع هذا المشارك.',
      attendeeNetworkingDisabled: 'لا يشارك هذا الحضور في مساحات الشبكات.',
      networkingDisabled: 'تم تعطيل الشبكات',
      privateChatDisabled: 'تم تعطيل الدردشة الخاصة',
      attendeeChatDisabled: 'لا يسمح هذا الحضور بطلبات الدردشة الخاصة',
      ratingHelp: 'قيم هذا الحضور وفقًا لاهتمامك بجدولة اجتماع معهم. سيساعدنا هذا في مطابقتك مع الحاضرين الآخرين الذين قد تكون مهتمًا بهم.',
      rated: 'تم تقييمه',
      contactOrganizer: 'للمشاركة الكاملة في هذا الحدث، يرجى الاتصال بالمنظم لمعرفة المزيد.',
      detailsRequired: 'هذا الحقل مطلوب',
    },
    requestInfo: {
      title: 'طلب معلومات الاتصال',
      subtitle: 'أرسل رسالة إلى هذا الحضور لتطلب منه مشاركة معلومات الاتصال الخاصة به معك.',
      defaultMessage: 'مرحبًا 👋! أود منك مشاركة معلومات الاتصال الخاصة بك معي 😁',
    },
    title: {
      attendees: 'الحاضرون',
      associatedWith: 'مرتبط بـ',
      description: 'احصل على اتصال وجدولة اجتماعات خاصة مع الحاضرين الآخرين.',
      profile: 'الملف الشخصي {{name}}',
    },
    filter: {
      filters: 'الفلاتر',
      typeOfAttendee: 'نوع الحضور',
      country: 'البلد',
      category: 'الفئة',
      meetingAvailability: 'توفر الاجتماعات',
      slot: 'حدد فتحة',
      filter: 'عامل تصفية',
    },
    placeholder: {
      allAttendeeTypes: 'جميع الأنواع',
      noSlotSelected: 'لم يتم تحديد فتحة'
    },
    data: {
      city: 'مدينة',
      region: 'المنطقة',
      country: 'البلد',
      email: 'البريد الإلكتروني',
      phone: 'هاتف',
      companyDescription: 'وصف الشركة',
      rating: 'تصنيف الحضور',
      ratingTableTitle: 'تقييم',
      attendee: 'الحاضر',
      attendeeType: 'النوع',
      attendanceMode: 'الوضعية',
    },
    actions: {
      listView: 'طريقة عرض القائمة',
      gridView: 'عرض الشبكة',
      clearSlot: 'فتحة واضحة',
      startChat: 'ابدأ الدردشة',
      goToChat: 'انتقل إلى الدردشة',
      denyParticipant: 'ليس هذه المرة',
      allowParticipant: 'مهتم بالتقييم',
      resetRating: 'إعادة تعيين التصنيف',
      requestContactInfo: 'طلب معلومات الاتصال',
      sendRequest: 'إرسال طلب',
    },
  },
  tr: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'Bu etkinlik yapılandırması, bu katılımcıyla toplantı planlamanıza izin vermez.',
      attendeeNetworkingDisabled: 'Bu katılımcı ağ alanlarına katılmıyor',
      networkingDisabled: 'Ağ devre dışı',
      privateChatDisabled: 'Özel sohbet devre dışı',
      attendeeChatDisabled: 'Bu katılımcı özel sohbet isteklerine izin vermez',
      ratingHelp: 'Bu katılımcıyı, onlarla bir toplantı planlamaya olan ilginize göre değerlendirin. Bu, ilginizi çekebilecek diğer katılımcılarla sizi eşleştirmemize yardımcı olacaktır.',
      rated: 'Dereceli',
      contactOrganizer: 'Bu etkinliğe tam olarak katılmak için lütfen daha fazla bilgi edinmek için organizatörle iletişime geçin.'
    },
    title: {
      attendees: 'Katılımcılar',
      associatedWith: 'İlişkili',
      description: 'İletişim kurun ve diğer katılımcılarla özel toplantılar planlayın.',
      profile: '{{name}} kullanıcısının profili'
    },
    filter: {
      filters: 'Filtreler',
      typeOfAttendee: 'Katılımcı türü',
      country: 'Ülke',
      category: 'Kategori',
      meetingAvailability: 'Toplantı kullanılabilirliği',
      slot: 'Bir yuva seçin',
      filter: 'Filtre'
    },
    placeholder: {
      allAttendeeTypes: 'Tüm tipler',
      noSlotSelected: 'Seçili yuva yok'
    },
    data: {
      city: 'Semt',
      region: 'Şehir',
      country: 'Ülke',
      email: 'E-posta',
      phone: 'Telefon',
      companyDescription: 'Şirket Tanımı',
      rating: 'Katılımcı derecelendirmesi',
      ratingTableTitle: 'Değerlendirme',
      attendee: 'Katılımcı',
      attendeeType: 'Tür',
      attendanceMode: 'Modality',
    },
    actions: {
      listView: 'Liste görünümü',
      gridView: 'Kılavuz görünümü',
      clearSlot: 'Temizle yuvası',
      startChat: 'Sohbet başlatin',
      goToChat: 'Sohbet başlatin',
      denyParticipant: 'Bu sefer değil.',
      allowParticipant: 'Derecelendirme ilgileniyor',
      resetRating: 'Değerlendirmeyi sıfırla',
    }
  },
  de: {
    info: {
      currentAttendeeCantRequestMeetingTo: 'Mit dieser Ereigniskonfiguration können Sie keine Besprechungen mit diesem Teilnehmer planen.',
      attendeeNetworkingDisabled: 'Dieser Teilnehmer nimmt nicht an Netzwerkbereichen teil',
      networkingDisabled: 'Netzwerkbetrieb ist deaktiviert',
      privateChatDisabled: 'Privatchat deaktiviert',
      attendeeChatDisabled: 'Dieser Teilnehmer erlaubt keine privaten Chat-Anfragen',
      ratingHelp: 'Bewerten Sie diesen Teilnehmer nach Ihrem Interesse, ein Meeting mit ihm zu vereinbaren. Auf diese Weise können wir Sie mit anderen Teilnehmern zusammenbringen, an denen Sie interessiert sein könnten.',
      rated: 'Bewertet',
      contactOrganizer: 'Um vollständig an dieser Veranstaltung teilzunehmen, wenden Sie sich bitte an den Veranstalter, um mehr zu erfahren.'
    },
    title: {
      attendees: 'Teilnehmer',
      associatedWith: 'Assoziiert mit',
      description: 'Nehmen Sie Kontakt auf und vereinbaren Sie private Besprechungen mit anderen Teilnehmern.',
      profile: 'Profil von {{name}}',
    },
    filter: {
      filters: 'Filtert',
      typeOfAttendee: 'Art des Teilnehmers',
      country: 'Land',
      category: 'Kategorie',
      meetingAvailability: 'Verfügbarkeit von Besprechungen',
      slot: 'Wählen Sie einen Platz',
      filter: 'Filtern'
    },
    placeholder: {
      allAttendeeTypes: 'Alle Typen',
      noSlotSelected: 'Kein Platz ausgewählt.'
    },
    data: {
      city: 'City',
      region: 'Region',
      country: 'Land',
      email: 'E-mail',
      phone: 'Telefon',
      companyDescription: 'Beschreibung des Unternehmens',
      rating: 'Bewertung der Teilnehmer',
      ratingTableTitle: 'Bewertung',
      attendee: 'Teilnehmer',
      attendeeType: 'Typ',
      attendanceMode: 'Modalität',
    },
    actions: {
      listView: 'Ansicht auflisten',
      gridView: 'Rasteransicht',
      clearSlot: 'Steckplatz löschen',
      startChat: 'Chat starten',
      goToChat: 'Gehe zum Chat',
      denyParticipant: 'Diesmal nicht',
      allowParticipant: 'Interessiert an einer Bewertung',
      resetRating: 'Bewertung zurücksetzen',
    },
  },
};
