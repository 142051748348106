import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import callApi from '../../actions/callApi';
import Rater from '../Rater';

const ParticipantRating = ({
  participant, ratings, denyList, callApi: dispatchCallApi, setMessage, disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const rating = Object.values(ratings).find(
    ({ participant: { id: participantId } }) => participantId === participant.id
  );
  const denied = !!Object.values(denyList).find(
    ({ blacklistedParticipant: { id: participantId } }) => participantId === participant.id
  );

  const { eventUri } = useParams();
  const { id: participantId, businessConference: { id: businessConferenceId } } = participant;
  const updateRating = (action, newRating) => {
    setLoading(true);
    dispatchCallApi(action, {
      eventUri, businessConferenceId, participantId, rating: newRating,
    }).then(({ error }) => {
      if (error) setMessage({ type: 'error', text: error });
      else setMessage();
      setLoading(false);
    });
  };
  const rate = (newRating) => updateRating(newRating === rating?.rate ? 'resetRating' : 'rateParticipant', newRating);

  return loading ? (
    <CircularProgress
      size={20}
      thickness={4.5}
      color="secondary"
    />
  ) : (
    <>
      <Rater
        value={rating?.rate ?? 0}
        onChange={rate}
        disabled={disabled || loading || denied}
      />
    </>
  );
};

ParticipantRating.propTypes = {
  participant: CustomPropTypes.participant.isRequired,
  ratings: PropTypes.objectOf(CustomPropTypes.rating),
  denyList: PropTypes.objectOf(CustomPropTypes.deniedParticipant),
  callApi: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ParticipantRating.defaultProps = {
  ratings: {},
  denyList: {},
  disabled: false,
};

const mapStateToProps = ({
  entities: {
    businessConferenceRatedPossibleMeetings: ratings,
    businessConferenceParticipantBlacklists: denyList,
  },
}) => ({
  ratings,
  denyList,
});

export default connect(mapStateToProps, { callApi })(ParticipantRating);
