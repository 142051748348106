import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LanguageIcon from '@material-ui/icons/Language';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Message from 'eventtia-ui-components/lib/Message';
import Accordion from 'eventtia-ui-components/lib/Accordion';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from 'eventtia-ui-components/lib/Button';
import Loader from 'eventtia-ui-components/lib/Loader';
import Chip from 'eventtia-ui-components/lib/Chip';
import TwitterXIcon from '../../assets/TwitterXIcon';
import BackButton from '../../components/BackButton';
import ShareDetailsToSponsor from '../../components/ShareDetailsToSponsor';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import callApi from '../../actions/callApi';
import { setActiveAttendee } from '../../actions/app';
import Stand from '../../components/Stand/index';
import AttachmentFile from '../../components/AttachmentFile';
import SponsorProduct from '../../components/SponsorProduct';
import RepresentativeCard from '../../components/RepresentativeCard';
import HotspotClient from '../../components/HotspotClient';
import { canChat } from '../../helpers/chat';
// import ActivitiesCard from '../../components/ActivitiesCard';
import Carrousel from '../../components/Carrousel';
import SponsorActivityCard from '../../components/SponsorActivityCard';
import { getCurrentAttendee, getCurrentPerson, getCurrentParticipant } from '../../helpers/getters';
import useTraces, { ENTITY_TYPES } from '../../hooks/useTraces';
import { ATTENDANCE_MODE } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'block',
    // alignItems: 'center',
    height: 194,
    marginTop: -32,
    marginLeft: -52,
    marginRight: -52,
    overflow: 'hidden',
  },
  mobileHeader: {
    marginLeft: -32,
    marginRight: -32,
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(5),
    top: 32,
  },
  bannerImage: {
    width: '100%',
    height: 194,
    objectFit: 'contain',
  },
  content: {
    paddingBottom: theme.spacing(10),
  },
  tabletContent: {
    maxWidth: '100%',
    marginBottom: theme.spacing(4),
  },
  title: {
    height: 'auto',
    minHeight: 98,
    width: '100%',
    display: 'flex',
    margin: theme.spacing(4.5, 0, 5.75, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: theme.customShadows.small,
    alignItems: 'center',
  },
  detail: {
    width: '58%',
  },
  logo: {
    maxHeight: '60px',
    width: '30%',
    objectFit: 'contain',
  },
  defaultLogo: {
    width: 35,
    height: 35,
    color: theme.palette.primary.main,
  },
  pipe: {
    color: theme.palette.lightGrey.dark,
    margin: theme.spacing(0, 2, 0, 2),
    fontSize: theme.spacing(4),
    lineHeight: '1em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  nameContainer: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  category: {
    color: theme.palette.darkGrey.light,
    fontSize: 14,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  website: {
    fontSize: 14,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  socialMediaContainer: {
    width: '35%',
  },
  socialMediaIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&>:nth-child(1)': {
      color: theme.palette.primary.main,
      borderRadius: '50%',
    },
  },
  subtitles: {
    fontSize: 16,
    margin: theme.spacing(5.7, 0, 3),
    fontWeight: 'bold',
    color: theme.palette.darkGrey.main,
  },
  companyDescriptionFull: {
    color: theme.palette.darkGrey.light,
    lineHeight: 2,
  },
  attachments: {
    margin: theme.spacing(4, 0, 3),
    fontWeight: 'bold',
    color: theme.palette.darkGrey.main,
  },
  filesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  emailbutton: {
    margin: theme.spacing(1),
  },
  message: {
    position: 'absolute',
    zIndex: 300,
    top: theme.spacing(3),
    right: theme.spacing(3),
    width: 465,
    marginBottom: theme.spacing(2),
  },
  mobileMessage: {
    position: 'absolute',
    zIndex: 300,
    top: theme.spacing(3),
    right: theme.spacing(3),
    width: '80%',
    marginLeft: -theme.spacing(9),
  },
  tabletContainer: {
    margin: theme.spacing(1, 0.5, 1, 0.5),
    borderRadius: 10,
    backgroundColor: theme.palette.darkGrey.dark,
    color: theme.palette.common.white,
    boxShadow: theme.customShadows.default,
    overflow: 'hidden',
    width: '35vw',
    height: 'calc(35vw * 0.5625)',
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  },
  mobileContainer: {
    margin: theme.spacing(1, 0, 1, 0),
    borderRadius: 10,
    backgroundColor: theme.palette.darkGrey.dark,
    color: theme.palette.common.white,
    boxShadow: theme.customShadows.default,
    overflow: 'hidden',
    width: '70vw',
    height: 'calc(70vw * 0.5625)',
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  },
  pcContainer: {
    margin: theme.spacing(1, 0.5, 1, 0.5),
    borderRadius: 10,
    backgroundColor: theme.palette.darkGrey.dark,
    color: theme.palette.common.white,
    boxShadow: theme.customShadows.default,
    overflow: 'hidden',
    width: 380,
    height: 'calc(380px * 0.5625)',
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  },
  sponsorVideosContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
  },
  representatives: {
    display: 'flex',
    overflow: 'auto',
    padding: theme.spacing(3, 0),
  },
  hotSpotsWrapper: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  socialLinks: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    marginRight: 10,
  },
  accordion: {
    margin: theme.spacing(2.5, 0, 0, -3),
  },
  sponsorDescriptionContainer: {
    marginBottom: theme.spacing(2.5),
  },
  hotSpotContentroot: {
    zIndex: 3,
    position: 'absolute',
    borderRadius: 10,
    backgroundColor: theme.palette.darkGrey.dark,
    color: theme.palette.common.white,
    boxShadow: theme.customShadows.default,
    overflow: 'hidden',
    width: '70%',
    height: '45%',
    '& > iframe': {
      width: '100%',
      height: '100%',
    },
  },
  attendanceModeAndName: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  attendanceModeChip: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
  },
}));

// const HotSpotContent = ({ hotSpot }) => {
//   const classes = useStyles();
//   return (
//     <div
//       style={{ top: `${hotSpot.coordinates?.y + 6}%`, left: `${hotSpot.coordinates?.x}%` }}
//       className={classes.hotSpotContentroot}
//       dangerouslySetInnerHTML={{ __html: hotSpot.content }}
//     />
//   );
// };

// HotSpotContent.propTypes = {
//   hotSpot: PropTypes.string.isRequired,
// };

const SponsorShow = ({
  entities,
  attendees,
  attendeeTypeCustomFields,
  sponsor,
  sponsorProducts,
  categories,
  callApi: dispatchCallApi,
  eventFiles,
  hotspots,
  businessConferenceParticipants: participants,
  attendeeTypes,
  businessConferenceParticipantTypes: participantTypes,
  setActiveAttendee: dispatchSetActiveAttendee,
  fetchingParticipant,
  sponsorRepresentatives,
  appSettings,
  workshops,
  attendeeWorkshops,
  scrollToTop,
}) => {
  const { eventUri } = useParams();
  const { t } = useTranslation('sponsors');
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const [shareDetailsDialog, setShareDetailsDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareSuccessMessage, setShareSuccesMessage] = useState('');
  const { attendanceMode } = sponsor;
  // const [openedHotSpot, setOpenedHotSpot] = useState();
  const [settings] = Object.values(appSettings);
  const {
    logo: {
      filename: logoFilename,
      medium: logoMedium,
    },
    attendee,
    id,
    fileCategoryId,
    website,
    description,
    name,
    bannerImage: {
      filename: baannerFilename,
      large: bannerLarge,
    },
    category,
    standLogo: {
      filename: standLogoFilename,
      medium: standLogo,
    },
    brandColor,
    slogan,
    standImageIndex,
    facebook,
    instagram,
    twitter,
    linkedin,
    mediaInfo,
  } = sponsor;

  const { currency, attendanceMode: eventAttendanceMode } = Object.values(entities.events)[0];

  const shareDetailsButton = (event) => {
    setAnchorEl(event.currentTarget);
    setShareDetailsDialog(!shareDetailsDialog);
  };

  const sponsorActivities = Object.values(workshops)
    .filter(({ sponsorId }) => Number(id) === sponsorId);

  const { trackOnce } = useTraces(id, ENTITY_TYPES.SPONSOR, true);

  const sponsorAttendee = attendee?.id && attendees[attendee.id];
  const currentRepresentative = sponsorAttendee && getCurrentPerson(sponsorAttendee,
    participants, attendeeTypes, attendeeTypeCustomFields);

  const sponsorAttendeeEmail = currentRepresentative?.email;

  const representativeAttendeeTypeId = currentRepresentative?.attendeeTypeId;
  const canTheOtherOneChat = canChat(settings, representativeAttendeeTypeId);
  const loggedInAttendee = getCurrentAttendee(entities);
  const { id: loggedInAttendeeId } = loggedInAttendee;
  const canIChat = canChat(settings, loggedInAttendee.attendeeType?.id);
  const activeChat = (canTheOtherOneChat && canIChat);

  const currentParticipant = getCurrentParticipant(entities) || {};
  const participantTypeId = currentParticipant?.participantTypeId;
  const currentParticipantType = participantTypeId && participantTypes[participantTypeId];
  const canRequestMeetingTo = currentParticipantType?.canRequestMeetingToAttendeeTypes;
  const disabledScheduling = !(canRequestMeetingTo || [])
    .includes(Number(representativeAttendeeTypeId));

  const urlFixed = (url) => {
    if (!/^https?:\/\//i.test(url)) return `http://${url}`;
    return url;
  };

  const otherRepresentatives = Object.values(sponsorRepresentatives).filter(
    ({ attendee: { id: repId }, sponsor: { id: spId }, active }) => (
      active && spId === sponsor.id && !!attendees[repId]
    )
  ).map(
    ({ attendee: { id: repId } }) => {
      const repAttendee = attendees[repId];
      const canTheOtherRepChat = canChat(settings, repAttendee.attendeeType.id);
      return {
        person: getCurrentPerson(
          repAttendee, participants, attendeeTypes, attendeeTypeCustomFields
        ),
        activeChat: canTheOtherRepChat && canIChat,
        disabledScheduling: !(canRequestMeetingTo || []).includes(
          Number(repAttendee.attendeeType.id)
        ),
      };
    }
  );

  // const participantId = sponsorAttendee?.businessConferenceParticipants?.[0]?.id;

  // const { businessConferenceId } = currentParticipant;

  // useEffect(() => {
  //   if (participantId) dispatchCallApi(
  //     'participant', { eventUri, businessConferenceId, participantId }
  //   );
  // }, [dispatchCallApi, eventUri, businessConferenceId, participantId]);

  useEffect(() => {
    if (id && fileCategoryId) dispatchCallApi('sponsorsFiles', { eventUri, id, fileCategoryId });
  }, [eventUri, id, fileCategoryId, dispatchCallApi]);

  useEffect(() => {
    dispatchCallApi('sponsorProducts', { eventUri, sponsorId: id });
  }, [dispatchCallApi, eventUri]);

  useEffect(() => {
    dispatchCallApi('hotspots', { eventUri, sponsorId: id });
  }, [dispatchCallApi, eventUri, id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const sendDetails = (message, shareContactInfo) => {
    setShareDetailsDialog(!shareDetailsDialog);
    const push = trackOnce(0, { meta_data: { message, share_contact_info: shareContactInfo } });
    if (push) push.receive('ok', () => {
      setShareSuccesMessage(t('data.results.shareSuccess'));
    }).receive('error', ({ reason }) => {
      // eslint-disable-next-line no-console
      console.error('failed to join channel', reason);
    });
  };
  const onCloseMessage = () => {
    setShareSuccesMessage('');
  };
  const showSocialMedia = () => (
    <>
      {website && (
        <a className={classes.socialLinks} href={urlFixed(website)} rel="noopener noreferrer" target="_blank">
          <LanguageIcon />
        </a>
      )}
      {facebook && (
        <a className={classes.socialLinks} href={facebook} rel="noopener noreferrer" target="_blank">
          <FacebookIcon />
        </a>
      )}
      {twitter && (
        <a className={classes.socialLinks} href={`https://www.twitter.com/${twitter}`} rel="noopener noreferrer" target="_blank">
          <TwitterXIcon />
        </a>
      )}
      {linkedin && (
        <a className={classes.socialLinks} href={linkedin} rel="noopener noreferrer" target="_blank">
          <LinkedInIcon />
        </a>
      )}
      {instagram && (
        <a className={classes.socialLinks} href={`https://www.instagram.com/${instagram}`} rel="noopener noreferrer" target="_blank">
          <InstagramIcon />
        </a>
      )}
    </>
  );

  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;

  const { HYBRID } = ATTENDANCE_MODE;

  const sponsorOwnProducts = Object.values(sponsorProducts)
    .filter((product) => product.sponsor.id === id);

  const sponsorFiles = Object.values(eventFiles).filter((file) => file.entityType === 'Sponsor' && file.entityId.toString() === id);
  const section = [{
    title: t('data.attachments'),
    content: (
      <div className={classes.filesContainer}>
        {!!sponsorFiles.length && sponsorFiles.map((file) => (
          <AttachmentFile key={file.id} file={file} source="sponsor" sourceId={id} />
        ))}
      </div>
    ),
  }];
  const attendeeWorkshopsArray = Object.values(attendeeWorkshops || {});
  const registeredWorkshops = attendeeWorkshopsArray
    ?.filter((attendeeRegisteredWorkshop) => (attendeeRegisteredWorkshop.attendee.id
      === loggedInAttendeeId))
    .map(({ workshop }) => workshop.id);
  const sponsorHotspots = Object.values(hotspots)
    .filter((hotspot) => hotspot?.sponsorId?.toString() === id);

  const orderedSponsorActivities = (sponsorActivities || [])
    .sort((a, b) => (new Date(a.startDate)).getTime() - (new Date(b.startDate)).getTime());
  return (
    <div>
      <div className={clsx(classes.header, mobile && classes.mobileHeader)}>
        <BackButton
          className={classes.backButton}
        />
        <img
          className={classes.bannerImage}
          src={baannerFilename ? bannerLarge : '/default-banner-sponsors.png'}
          alt={name}
        />
      </div>
      <div className={clsx(classes.content, tablet && classes.tabletContent)}>
        <Message
          className={clsx(classes.message, mobile && classes.mobileMessage)}
          type="success"
          title={t('data.actions.success')}
          onClose={onCloseMessage}
          message={shareSuccessMessage}
        />
        <div className={classes.title}>
          {logoFilename ? (
            <img className={classes.logo} src={logoMedium} alt={name} />
          ) : (
            <BusinessIcon className={classes.defaultLogo} />
          )}
          <span className={classes.pipe}>|</span>
          <div className={classes.nameContainer}>
            { category?.id && (
              <Typography className={classes.category} variant="subtitle2">
                {categories[category?.id].name}
              </Typography>
            )}
            <div className={classes.attendanceModeAndName}>
              <Typography className={classes.website} variant="subtitle1">
                {name}
              </Typography>
              {eventAttendanceMode === HYBRID && attendanceMode && (
                <Chip
                  label={t(`global:${stringAttendanceMode}`)}
                  size="small"
                  className={classes.attendanceModeChip}
                />
              )}
            </div>
          </div>
          <span className={classes.pipe}>|</span>
          <div className={classes.socialMediaContainer}>
            {showSocialMedia()}
          </div>
        </div>
        {description && (
          <div className={classes.sponsorDescriptionContainer}>
            <Typography className={classes.subtitles} variant="body1">
              {t('data.companyDescription')}
            </Typography>
            <div
              className={classes.companyDescriptionFull}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        )}

        <ShareDetailsToSponsor
          closeDialog={shareDetailsButton}
          action={{ name: 'Send', onClick: sendDetails }}
          open={shareDetailsDialog}
          anchorEl={anchorEl}
          companyName={name}
        />
        <Button variant="primary" onClick={shareDetailsButton} className={classes.emailbutton}>
          {t('data.actions.shareDetails')}
        </Button>
        {!!sponsorAttendeeEmail && (
          <Button variant="secondary" href={`mailto:${sponsorAttendeeEmail}`} className={classes.emailbutton}>
            {t('data.actions.sendEmail')}
          </Button>
        )}

        {((standImageIndex || standImageIndex === 0) && standImageIndex !== 'null') && (
          <>
            <Typography className={classes.subtitles} variant="body1">
              {t('data.exhibitionStand')}
            </Typography>
            <div className={classes.hotSpotsWrapper}>
              <Stand
                standImageIndex={standImageIndex}
                logo={{ image: standLogo, name: standLogoFilename }}
                slogan={slogan}
                sloganTextColor={brandColor}
              />
              {sponsorHotspots?.length > 0 && sponsorHotspots.map((hotspot) => (
                <HotspotClient
                  key={hotspot.id}
                  hotspot={hotspot}
                  brandColor={brandColor}
                  eventFiles={eventFiles}
                  // onClick={setOpenedHotSpot}
                />
              ))}
              {/* {openedHotSpot && (
                <HotSpotContent hotSpot={openedHotSpot} />
              )} */}
            </div>
          </>
        )}
        {currentRepresentative && (activeChat
          || !disabledScheduling) && (
            <>
              <Typography className={classes.subtitles} variant="body1">
                {t('data.contactUs')}
              </Typography>
              {fetchingParticipant ? (
                <Loader loading />
              ) : (
                <div className={classes.representatives}>
                  <RepresentativeCard
                    key={currentRepresentative.id}
                    currentRepresentative={currentRepresentative}
                    currentLoggedInId={loggedInAttendeeId}
                    onBlockClick={() => {
                      dispatchSetActiveAttendee(attendee?.id);
                    }}
                    disabledScheduling={disabledScheduling}
                    activeChat={activeChat}
                  />
                  {otherRepresentatives.map((rep) => (
                    <RepresentativeCard
                      key={rep.person.id}
                      currentRepresentative={rep.person}
                      currentLoggedInId={loggedInAttendeeId}
                      onBlockClick={() => {
                        dispatchSetActiveAttendee(rep.person.id);
                      }}
                      disabledScheduling={rep.disabledScheduling}
                      activeChat={rep.activeChat}
                    />
                  ))}
                </div>
              )}
            </>
        )}
        {orderedSponsorActivities?.length > 0 && (
          <>
            <Typography className={classes.subtitles} variant="body1">
              {t('data.activities')}
            </Typography>
            <Carrousel>
              {sponsorActivities.map((workshop) => (
                <SponsorActivityCard
                  key={workshop.id}
                  sponsorActivity={workshop}
                  registeredWorkshops={registeredWorkshops}
                />
              ))}
            </Carrousel>
          </>
        )}
        {(!!mediaInfo?.length && !!mediaInfo?.[0]?.trim()) && (
          <>
            <Typography className={classes.attachments} variant="body1">
              {t('data.videos')}
            </Typography>

            <div className={classes.sponsorVideosContainer}>
              {(mediaInfo && mediaInfo.length > 0) && mediaInfo.map((videoFrame) => (
                <div
                  key={videoFrame}
                  className={clsx(
                    tablet && classes.tabletContainer,
                    mobile && classes.mobileContainer,
                    !mobile && !tablet && classes.pcContainer
                  )}
                  dangerouslySetInnerHTML={{ __html: videoFrame }}
                />
              ))}
            </div>
          </>
        )}
        {sponsorOwnProducts.length > 0 && (
          <>
            <Typography className={classes.subtitles} variant="body1">
              {t('data.marketplace')}
            </Typography>
            <Grid container spacing={3}>
              {sponsorOwnProducts.map((product) => (
                <Grid item xs={12} md={6} lg={12}>
                  <SponsorProduct product={product} currency={currency} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {(!!sponsorFiles && sponsorFiles?.length > 0) && (
          <div className={classes.accordion}>
            <Accordion sections={section} defaultExpandedPanel={0} />
          </div>
        )}
      </div>
    </div>
  );
};

SponsorShow.propTypes = {
  entities: CustomPropTypes.entities.isRequired,
  attendees: PropTypes.objectOf(
    CustomPropTypes.attendee
  ),
  attendeeTypeCustomFields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  businessConferenceParticipants: PropTypes.objectOf(
    CustomPropTypes.participant
  ),
  attendeeTypes: PropTypes.objectOf(
    CustomPropTypes.attendeeType
  ),
  businessConferenceParticipantTypes: PropTypes.objectOf(
    CustomPropTypes.participantType
  ),
  sponsor: CustomPropTypes.sponsor,
  callApi: PropTypes.func.isRequired,
  eventFiles: PropTypes.objectOf(
    CustomPropTypes.eventFile
  ),
  categories: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setActiveAttendee: PropTypes.func.isRequired,
  fetchingParticipant: PropTypes.bool.isRequired,
  workshops: PropTypes.objectOf(
    CustomPropTypes.workshop
  ),
  appSettings: PropTypes.objectOf(
    CustomPropTypes.appSettings
  ).isRequired,
  hotspots: PropTypes.objectOf(
    CustomPropTypes.hotspot
  ),
  sponsorRepresentatives: PropTypes.objectOf(
    CustomPropTypes.sponsorRepresentative
  ),
  sponsorProducts: PropTypes.objectOf(CustomPropTypes.product),
  attendeeWorkshops: PropTypes.objectOf(
    CustomPropTypes.attendeeWorkshops
  ),
  scrollToTop: PropTypes.func.isRequired,
};

SponsorShow.defaultProps = {
  workshops: {},
  attendees: {},
  businessConferenceParticipants: {},
  attendeeTypes: {},
  businessConferenceParticipantTypes: {},
  attendeeTypeCustomFields: {},
  sponsor: {},
  sponsorRepresentatives: {},
  eventFiles: {},
  categories: {},
  hotspots: {},
  sponsorProducts: {},
  attendeeWorkshops: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    attendeeTypeCustomFields,
    attendees,
    eventFiles,
    businessConferenceParticipants,
    attendeeTypes,
    businessConferenceParticipantTypes,
    appSettings,
    hotspots,
    workshops,
    sponsorProducts,
    sponsorRepresentatives,
    attendeeWorkshops,
  },
  fetchStatus: {
    participant: {
      isFetching,
    },
  },
}) => ({
  workshops,
  attendeeTypeCustomFields,
  entities,
  attendees,
  eventFiles,
  businessConferenceParticipants,
  attendeeTypes,
  businessConferenceParticipantTypes,
  fetchingParticipant: isFetching,
  appSettings,
  hotspots,
  sponsorProducts,
  sponsorRepresentatives,
  attendeeWorkshops,
});
export default connect(mapStateToProps, {
  setActiveAttendee,
  callApi,
})(SponsorShow);
