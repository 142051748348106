import React, { useContext, useState } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DenyIcon from '@material-ui/icons/HighlightOff';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Chip from 'eventtia-ui-components/lib/Chip';
import Button from 'eventtia-ui-components/lib/Button';
import { connect } from 'react-redux';
import clsx from 'clsx';
import emailRegexp from '../../helpers/strings';
import { ATTENDANCE_MODE } from '../../helpers/constants';
import getMeetingVisibility from '../../helpers/meetings';
import CustomPropTypes from '../../helpers/CustomPropTypes';
import { buildPrivateTopic, canChat } from '../../helpers/chat';
import { getEntity, getSponsorByAttendee, getModuleByType } from '../../helpers/getters';
import useStringTransforms from '../../hooks/useStringTransforms';
import CustomFieldValue from '../CustomFieldValue';
import ParticipantRating from '../ParticipantRating';
import RequestContactInfo from '../RequestContactInfo';
import SponsorCardAlt from '../SponsorCard/SponsorsCardAlt';
import { setActiveSponsor, setActiveChannel } from '../../actions/app';
import { addChannel, NEW_CHAT_REQUEST, NEW_MESSAGE } from '../../actions/messages';
import moduleEnabledForCurrentAttendeeType from '../../helpers/moduleEnabledForCurrentAttendeeType';
import AttendeeBroadcastContext from '../../contexts/AttendeeBroadcastContext';

const Accordion = withStyles((theme) => ({
  root: {
    zIndex: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    '&:last-of-type': {
      borderRadius: 0,
    },
    '&:first-of-type,': {
      borderRadius: 0,
    },
    '&$expanded': {
      marginTop: 0,
    },
  },
  expanded: {
    backgroundColor: theme.palette.lightGrey.light,
  },
}))(MuiExpansionPanel);

const AccordionSummary = withStyles((theme) => ({
  root: {
    display: 'flex',
    height: 72,
    flexDirection: 'row-reverse',
    padding: theme.spacing(0, 2),
    paddingLeft: 0,
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    '&$expanded': {
    },
    [theme.breakpoints.down('sm')]: {
      height: 162,
    },
  },
  disabled: {
    backgroundColor: theme.palette.lightGrey.light,
    opacity: 100,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
    '&$expanded': {
      margin: theme.spacing(1.5, 0),
    },
  },
  expanded: {
  },
  expandIcon: {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 0,
      top: 18,
    },
    '&$expanded': {
      color: theme.palette.primary.main,
      transform: 'rotate(270deg)',
    },
  },
}))(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    maxHeight: 245,
    overflowY: 'auto',
    backgroundColor: theme.palette.lightGrey.light,
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expandDisabled: {
    backgroundColor: theme.palette.background.default,
  },
  expandIconHidden: {
    opacity: 0,
  },
  detailsHidden: {
    display: 'none',
  },
  basicInfo: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      gap: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  gridThreeColumns: {
    gridTemplateColumns: '3fr 2fr 1fr',
  },
  gridFourColumns: {
    gridTemplateColumns: '3fr 1fr 1fr 136px',
  },
  extraInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  chipsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      flexDirection: 'row',
    },
  },
  avatar: {
    height: 50,
    width: 50,
    backgroundColor: theme.palette.lightGrey.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      height: 64,
      width: 64,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(6.25),
    },
  },
  fallBackAvatar: {
    height: '50%',
    width: '50%',
  },
  notViewedWrapper: {
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    fontSize: 30,
    padding: theme.spacing(0.25),
    '& > img': {
      borderRadius: '50%',
    },
  },
  name: {
    fontSize: 14,
    marginBottom: theme.spacing(0.8),
    textAlign: 'start',
    fontWeight: 'bold',
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  description: {
    marginLeft: theme.spacing(2),
    width: 'inherit',
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  detail: {
    textAlign: 'start',
    fontSize: 12,
    color: theme.palette.darkGrey.light,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      // overflow: 'unset',
    },
  },
  ratingWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  data: {
    marginBottom: theme.spacing(2.5),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
  },
  email: {
    color: '#2892EE',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  infoChip: {
    flexDirection: 'row',
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    boxShadow: '0px 1px 2px #0000001F',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 'bold',
    display: 'flex',
    width: 'auto',
    height: 20,
    '& > svg': {
      color: theme.palette.primary.main,
      width: 14,
      height: 14,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-0.5),
    },
  },
  attendanceModeChip: {
    fontSize: 12,
    width: 84,
    height: 20,
    backgroundColor: fade(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.5, 0),
      marginLeft: theme.spacing(-0.5),
    },
  },
  fieldAttendanceMode: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dataTitle: {
    opacity: '0.6',
    marginBottom: 6,
    fontSize: 12,
    color: theme.palette.darkGrey.light,
  },
  anchor: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    color: theme.palette.darkGrey.main,
    display: 'block',
    width: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  imgData: {
    width: 150,
  },
  dataBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  barCircle: {
    height: 10,
    width: 10,
    borderRadius: 5,
    border: `solid ${theme.palette.lightGrey.dark} 2px`,
  },
  bar: {
    width: 2,
    height: 'calc(100% - 20px)',
    backgroundColor: theme.palette.lightGrey.dark,
  },
  sponsorBannerWrapper: {
    maxWidth: 270,
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  actionsContainerExtraMargin: {
    marginLeft: theme.spacing(3),
  },
  actionButton: {
    width: 270,
    '&:first-of-type,': {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type,': {
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const DecorationBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.dataBar}>
      <span className={classes.barCircle} />
      <span className={classes.bar} />
      <span className={classes.barCircle} />
    </div>
  );
};

const PersonListCard = ({
  expanded, setExpanded, person, type, events, viewedAttendees, currentAttendee, entities,
  participants, attendeeTypeCustomFields, sponsors, setActiveSponsor: dispatchSetActiveSponsor,
  appSettings, broadcasts, openChannels, addChannel: dispatchAddChannel,
  setActiveChannel: dispatchSetActiveChannel, setMessage, ratingEnabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('attendees', 'global');
  const { toTitleCase, toSentenceCase } = useStringTransforms();
  const {
    id,
    attendeeTypeId,
    participantId,
    firstName,
    lastName,
    company,
    position,
    email,
    cityId,
    telephone,
    fields,
    aliases,
    headshot,
    picture,
    attendeeTypeName,
    denied,
    attendanceMode,
    canScheduleMeetings: otherCanScheduleMeetings,
  } = person;
  const viewed = !!viewedAttendees?.[id];
  const [settings] = Object.values(appSettings);
  const attendeeBroadcast = useContext(AttendeeBroadcastContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [requestContactDialog, setRequestContactDialog] = useState(false);
  const fullName = `${firstName} ${lastName}`;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const stringAttendanceMode = `attendanceMode.${attendanceMode}`;
  const [{ attendanceMode: eventAttendanceMode }] = Object.values(events);
  const pipe = position && company ? ' | ' : '';
  const job = (position || company) ? `${toTitleCase(company, true)}${pipe}${toTitleCase(position, true)}` : '';
  const { HYBRID } = ATTENDANCE_MODE;

  const sponsor = getSponsorByAttendee(sponsors, id) || {};
  const { inSchedulingTime } = getMeetingVisibility(entities);
  const { canScheduleMeetings: selfCanScheduleMeetings } = currentAttendee;
  const canScheduleMeetings = selfCanScheduleMeetings && otherCanScheduleMeetings;

  const conference = getEntity(entities, 'businessConferences');
  const participant = participants?.[participantId];

  const leadsModule = Object.values(settings.modules).find((mod) => mod.type === 'AttendeeLeads');
  const canIChat = canChat(settings, currentAttendee.attendeeType.id);
  const canTheOtherOneChat = canChat(settings, attendeeTypeId);

  const otherHasLeads = leadsModule.customParams.availableForAttendeeTypes
  ?.includes(attendeeTypeId);

  const attendeeRatingEnable = canScheduleMeetings && conference?.automaticScheduling
    && inSchedulingTime;
  const hybridEvent = eventAttendanceMode === HYBRID;

  const threeColumnsLayout = (ratingEnabled && !hybridEvent) || (!ratingEnabled && hybridEvent);
  const fourColumnsLayout = ratingEnabled && hybridEvent;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSponsorCard = () => {
    dispatchSetActiveSponsor(sponsor.id);
  };

  const topic = buildPrivateTopic(id, currentAttendee.id);
  const broadcast = broadcasts[topic];
  const isChatOpen = openChannels.some(({ topic: openTopic }) => openTopic === topic);
  const startChat = (redirect) => {
    if (!isChatOpen) {
      attendeeBroadcast(NEW_CHAT_REQUEST, { attendee_id: id });
      dispatchAddChannel({
        type: 'private',
        topic,
        name: fullName,
        avatar: headshot?.filename && headshot.small,
      });
    }
    if (redirect) dispatchSetActiveChannel(topic);
  };
  const canAddLeads = getModuleByType(settings.modules, 'AttendeeLeads')?.enabled;
  const showLeadsOptions = moduleEnabledForCurrentAttendeeType(entities, 'AttendeeLeads');

  const requestContactInfo = (event) => {
    startChat();
    setAnchorEl(event.currentTarget);
    setRequestContactDialog(!requestContactDialog);
  };

  const requestDetails = (msg) => {
    if (broadcast) {
      broadcast(NEW_MESSAGE, { body: msg });
      dispatchSetActiveChannel(topic);
    }
  };

  const attendeeCustomFields = Object.values(attendeeTypeCustomFields)
    .sort((a, b) => (a.order - b.order))
    .filter(({ id: fieldKey }) => (
      fields[fieldKey] && attendeeTypeCustomFields[fieldKey]
        && !(attendeeTypeCustomFields[fieldKey].default)
    ));

  const attendeeCustomFieldsKeys = attendeeCustomFields.map(({ id: fieldKey }) => fieldKey);

  const hasExtraFields = !!attendeeCustomFieldsKeys.length || !!sponsor.length
  || (cityId || email || telephone);

  const hasChatOptions = canIChat && canTheOtherOneChat;
  const expandDisabled = !hasExtraFields && !hasChatOptions;

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === id}
        onChange={handleChange(id)}
        classes={expandDisabled ? { root: classes.expandDisabled } : undefined}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={expandDisabled ? { expandIcon: classes.expandIconHidden } : undefined}
        >
          {mobile && (
            <Avatar
              src={type === 'attendee' ? (headshot?.filename && headshot.medium)
                : (picture?.filename && picture.large)}
              variant="circle"
              className={clsx(classes.avatar, !viewed && classes.notViewedWrapper)}
            />
          )}
          <div className={clsx(classes.basicInfo, threeColumnsLayout && classes.gridThreeColumns,
            fourColumnsLayout && classes.gridFourColumns)}
          >
            <div className={classes.avatarWrapper}>
              {!mobile && (
                <Avatar
                  src={type === 'attendee' ? (headshot?.filename && headshot.medium)
                    : (picture?.filename && picture.large)}
                  variant="circle"
                  className={clsx(classes.avatar, !viewed && classes.notViewedWrapper)}
                  classes={{ fallback: classes.fallBackAvatar }}
                />
              )}
              <div className={classes.description}>
                {type === 'attendee' ? (
                  <Typography className={classes.name} variant="subtitle2">
                    {firstName || lastName
                      ? `${toTitleCase(firstName)} ${toTitleCase(lastName)}` : <br />}
                  </Typography>
                ) : (
                  <Typography className={classes.name} variant="subtitle2">
                    {toTitleCase(fullName)}
                  </Typography>
                )}
                {job && (
                  <Typography className={classes.detail} variant="body2">
                    {toTitleCase(job, true)}
                  </Typography>
                )}
                {mobile && attendeeTypeName && (
                  <Typography className={classes.detail} variant="body2">
                    {toTitleCase(attendeeTypeName, true)}
                  </Typography>
                )}
              </div>
            </div>
            {!mobile && attendeeTypeName && (
              <Typography className={classes.detail} variant="body2">
                {toTitleCase(attendeeTypeName, true)}
              </Typography>
            )}
            {hybridEvent && attendanceMode && (
              <Chip
                label={t(`global:${stringAttendanceMode}`)}
                size="small"
                className={classes.attendanceModeChip}
              />
            )}
            {attendeeRatingEnable && !!denied && (
              <Chip
                className={classes.infoChip}
                label={t('actions.denyParticipant')}
                icon={<DenyIcon />}
                size="small"
              />
            )}
            {attendeeRatingEnable && !denied && (
              <div className={classes.ratingWrapper}>
                <Typography
                  className={classes.rating}
                  component="div"
                  role="button"
                  tabIndex="0"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <ParticipantRating
                    participant={participant}
                    setMessage={setMessage}
                  />
                </Typography>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails classes={expandDisabled ? { root: classes.detailsHidden } : undefined}>
          <div className={classes.extraInfo}>
            {hasExtraFields && (
              <DecorationBar />
            )}
            <div>
              {cityId?.country && (
                <div>
                  <Typography className={classes.dataTitle} variant="body2">
                    {t('data.country')}
                  </Typography>
                  <Typography className={classes.data} variant="subtitle1">
                    {cityId.country}
                  </Typography>
                </div>
              )}
              {cityId?.region && (
                <div>
                  <Typography className={classes.dataTitle} variant="body2">
                    {t('data.region')}
                  </Typography>
                  <Typography className={classes.data} variant="subtitle1">
                    {cityId.region}
                  </Typography>
                </div>
              )}
              {cityId?.city && (
                <div>
                  <Typography className={classes.dataTitle} variant="body2">
                    {aliases.cityId || t('data.city')}
                  </Typography>
                  <Typography className={classes.data} variant="subtitle1">
                    {cityId.city}
                  </Typography>
                </div>
              )}
              {email && (
                <div>
                  <Typography className={classes.dataTitle} variant="body2">
                    {aliases.email || t('data.email')}
                  </Typography>
                  <Typography className={classes.data} variant="subtitle1">
                    {emailRegexp.test(email) ? (
                      <a className={classes.email} href={`mailto:${email}`}>{email}</a>
                    ) : email}
                  </Typography>
                </div>
              )}
              {telephone && (
                <div>
                  <Typography className={classes.dataTitle} variant="body2">
                    {aliases.telephone || t('data.phone')}
                  </Typography>
                  <Typography className={classes.data} variant="subtitle1">
                    {telephone}
                  </Typography>
                </div>
              )}
              {attendeeCustomFieldsKeys.map((fieldKey) => (
                <div key={fieldKey}>
                  <Typography className={classes.dataTitle} variant="body2">
                    {toSentenceCase(attendeeTypeCustomFields[fieldKey].name)}
                  </Typography>
                  <CustomFieldValue
                    field={attendeeTypeCustomFields[fieldKey]}
                    value={fields[fieldKey]}
                    classes={{
                      anchorValue: classes.anchor,
                      value: classes.data,
                      img: classes.imgData,
                    }}
                  />
                </div>
              ))}
              {Object.keys(sponsor).length > 0 && (
                <>
                  <Typography className={classes.dataTitle} variant="body2">
                    {t('attendees:title.associatedWith')}
                  </Typography>
                  <Grid className={classes.sponsorBannerWrapper}>
                    <SponsorCardAlt
                      sponsor={sponsor}
                      onBlockClick={handleSponsorCard}
                    />
                  </Grid>
                </>
              )}
              <div
                className={clsx(classes.actionsContainer, !hasExtraFields
                  && classes.actionsContainerExtraMargin)}
              >
                {canIChat && canTheOtherOneChat && (
                  <Button
                    fullWidth
                    onClick={() => startChat(true)}
                    className={classes.actionButton}
                    disabled={!canTheOtherOneChat}
                  >
                    {t(isChatOpen ? 'actions.goToChat' : 'actions.startChat')}
                  </Button>
                )}
                {canIChat && canAddLeads && showLeadsOptions && otherHasLeads && (
                  <Button
                    variant="secondary"
                    onClick={requestContactInfo}
                    className={classes.actionButton}
                  >
                    {t('actions.requestContactInfo')}
                  </Button>
                )}
                <RequestContactInfo
                  isChatOpen={isChatOpen}
                  closeDialog={requestContactInfo}
                  action={requestDetails}
                  open={requestContactDialog}
                  anchorEl={anchorEl}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

PersonListCard.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
  entities: CustomPropTypes.entities.isRequired,
  person: CustomPropTypes.person.isRequired,
  events: PropTypes.objectOf(
    CustomPropTypes.event
  ).isRequired,
  type: PropTypes.string,
  viewedAttendees: CustomPropTypes.viewedAttendess,
  currentAttendee: CustomPropTypes.attendee.isRequired,
  participants: PropTypes.objectOf(CustomPropTypes.participant),
  attendeeTypeCustomFields: PropTypes.objectOf(
    CustomPropTypes.attendeeTypeCustomField
  ),
  sponsors: PropTypes.objectOf(
    CustomPropTypes.sponsor
  ),
  setActiveSponsor: PropTypes.func.isRequired,
  appSettings: PropTypes.objectOf(
    CustomPropTypes.appSettings
  ).isRequired,
  broadcasts: PropTypes.objectOf(PropTypes.func).isRequired,
  openChannels: PropTypes.arrayOf(CustomPropTypes.channel).isRequired,
  addChannel: PropTypes.func.isRequired,
  setActiveChannel: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  ratingEnabled: PropTypes.bool.isRequired,
};

PersonListCard.defaultProps = {
  expanded: false,
  type: 'attendee',
  viewedAttendees: {},
  participants: {},
  attendeeTypeCustomFields: {},
  sponsors: {},
};

const mapStateToProps = ({
  entities,
  entities: {
    appSettings,
    sponsors,
    events,
    businessConferenceParticipants,
  },
  chat: {
    openChannels,
  },
  viewedAttendees,
}) => ({
  entities,
  appSettings,
  sponsors,
  events,
  viewedAttendees,
  openChannels,
  participants: businessConferenceParticipants,
});

export default connect(mapStateToProps,
  {
    setActiveSponsor,
    addChannel,
    setActiveChannel,
  })(PersonListCard);
