import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GridViewIcon = (props) => (
  <SvgIcon {...props}>
    <svg id="grid_view_24dp_5F6368" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 17">
      <path id="Path_10894" data-name="Path 10894" d="M0,0H20V20H0Z" fill="none" />
      <path id="Path_10895" data-name="Path 10895" d="M3,3V8.333H8.333V3ZM3,9.667V15H8.333V9.667ZM9.667,3V8.333H15V3Zm0,6.667V15H15V9.667Z" transform="translate(1 1)" />
    </svg>
  </SvgIcon>
);

export default GridViewIcon;
